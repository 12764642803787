import { Link, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { registerSchema } from './schema'
import { registerUser } from './helpers'
import { useAuth } from './AuthProvider'
import { FormField } from './Components'


export default function Register() {
  const auth = useAuth()
  const navigate = useNavigate()
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        confirmPassword: '',
        name: '',
        organizationName: '',
      }}
      validationSchema={registerSchema}
      onSubmit={async (values, { setSubmitting }) => {
        // alert(JSON.stringify(values, null, 2))
        try {
          const response = await registerUser(auth, values)
          if (response.statuscode !== 201) {
            throw new Error(response.message)
          }
          navigate('/')
        } catch (err) {
          if (err instanceof Error) {
            alert(err.message)
          }
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <div className="container-md col-md-8 col-xl-6">
            <h1 className="h3">Register User</h1>
            <FormField type="email" name="email" placeholder="enter your email address" fieldLabel="Email" />
            <FormField type="password" name="password" placeholder="enter your password, minimum 8 chars" fieldLabel="Password" />
            <FormField type="password" name="confirmPassword" placeholder="confirm your password" fieldLabel="Confirm Password" />
            <FormField name="name" placeholder="your name" fieldLabel="Fullname" />
            <FormField name="organizationName" placeholder="your organization" fieldLabel="Organization" />

            <div className="mt-3 d-flex flex-row">
              <Link className="btn btn-warning flex-grow-1" to='/'>Back to main page</Link>
              <div className="mx-2"></div>
              <button className="btn btn-primary flex-grow-1" type="submit" disabled={isSubmitting || !isValid}>Register</button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
