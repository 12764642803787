import { Formik, Form } from 'formik'
import { saveProjects } from './helpers'
import ModalDialog from './ModalDialog'
import { useAuth } from './AuthProvider'
import { FormField, FormTextAreaField } from './Components'
import { projectSchema, IProject } from './schema'


function ProjectEditor({ isOpen, project, onSave, onClose }: {
  isOpen?: boolean,
  project: IProject,
  onSave: (project: IProject) => void | Promise<void>,
  onClose: () => void,
}) {
  const auth = useAuth()
  const pixelEnabled = project.subscriptionEnd && project.subscriptionEnd >= new Date()

  const isInIframe = window.location !== window.parent.location

  const handleStripeCheckout = async () => {
    // stripe checkout won't work in iframe
    if (isInIframe) return
    try {
      const session = await auth.postJson<{ url: string }>(`/stripe/create-checkout-session`, {
        projectId: project.id,
        successUrl: window.location.href,
        cancelUrl: window.location.href,
        restUrl: `${project.name}/wixelpress/v1/subscription-updated`,
      })
      window.location.assign(session.url)
    } catch (err) {
      console.error(err)
    }
  }

  if (!isOpen) return null
  return (
    <Formik
      initialValues={project}
      validationSchema={projectSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const result = await saveProjects(auth, values)
          onSave(result)
          onClose()
        } catch (err) {
          console.error(err)
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            title="Project Editor"
            footer={(
              <div className="btn-group">
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Save</button>
                <button className="btn btn-danger" onClick={onClose}>Close</button>
              </div>
            )}
          >
            <FormField name="name" fieldLabel="Project Name" placeholder="e.g. example.com"/>
            <h5 className="border-top w-100 mt-3 pt-1">Google Analytics</h5>
            <FormField name="gaConfig.measurementId" fieldLabel="Measurement ID" placeholder="G-XXXXXX" />
            {/* <FormField name="gaConfig.apiKey" fieldLabel="API Key" />
            <FormField name="gaConfig.appId" fieldLabel="App ID" />
            <FormField name="gaConfig.authDomain" fieldLabel="Auth Domain" />
            <FormField name="gaConfig.messagingSenderId" fieldLabel="Messaging Sender ID" />
            <FormField name="gaConfig.storageBucket" fieldLabel="Storage Bucket" /> */}

            <div className="d-flex w-100 border-top mt-3 pt-1 align-items-baseline">
              <h5 className="">
                Facebook Pixel
              </h5>
              {!pixelEnabled && (
                <>
                  <a
                    className="btn btn-sm btn-outline-primary ms-3"
                    href={`mailto:wixel@digitalwill.co.jp?subject=Upgrade request for site: ${project.name}&body=I want to use facebook conversions api with wixelpress.`}
                    title="Please upgrade to premium"
                  >
                    📧 Premium features
                  </a>
                  { !isInIframe && (
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-success ms-3"
                      onClick={handleStripeCheckout}
                      disabled={isInIframe}
                      title={isInIframe ? "Do not open in iframe" : "Stripe Checkout"}
                    >
                      Upgrade
                    </button>
                  )}
                </>
              )}
            </div>
            <FormField name="fbConfig.pixelId" fieldLabel="Pixel ID" disabled={!pixelEnabled} title={pixelEnabled ? "" : "Premium features"} />
            <FormField name="fbConfig.fbAccessToken" fieldLabel="FB Access Token" disabled={!pixelEnabled} title={pixelEnabled ? "" : "Premium features"} />
            <FormField name="fbConfig.testEventCode" fieldLabel="Test Event Code" disabled={!pixelEnabled} title={pixelEnabled ? "" : "Premium features"} />
            <FormTextAreaField name="selectors" fieldLabel="Selectors" placeholder="JSON formatted" />
          </ModalDialog>
        </Form>
      )}
    </Formik >
  )
}

export default ProjectEditor
