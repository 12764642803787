import type { Asserts } from 'yup'
import { TrackingEventsPayload } from "wixel_interfaces"
import { registerSchema, projectsListSchema, IProject, ApiResponse, patchUserSchema } from "./schema"
import type { AuthType } from './AuthProvider'

export const logoutCallback = (error: string) => {
  localStorage.removeItem("dw_wixel_jwt_token")
  // TODO replace by react-router-dom redirect solution
  window.location.href = '/'
}

export const wFetch = (path: string, config: any, cb: any, errorCb = logoutCallback) => {
  const token = localStorage.getItem("dw_wixel_jwt_token") || false
  config = {
    ...config,
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-type": "application/json"
    }
  }
  fetch(`${process.env.REACT_APP_NOT_BACKEND_HOST}${path}`, config)
    .then(res => res.json())
    .then(res => {
      if (res.statuscode === 401) {
        logoutCallback("Unauthorized")
      } else {
        cb(res)
      }
    }).catch(error => {
      if (errorCb.toString() !== logoutCallback.toString()) {
        errorCb(error)
      }
    })
}

/**
 * to fetch the projects from the backend
 * @param auth the auth object from useAuth
 * @returns list of projects
 */
export async function fetchProjects(auth: AuthType) {
  const data = await auth.getJson('/projects')
  return await projectsListSchema.validate(data.payload)
}

/**
 * saveProjects is use to save the project to the backend
 * @param auth the auth object from useAuth
 * @param project the project to save
 * @returns response json data
 */
export async function saveProjects(auth: AuthType, project: IProject) {
  let response: ApiResponse
  if (project.id && typeof project.id === 'number' && project.id > 0) {
    // update project using PUT
    response = await auth.putJson<ApiResponse>(`/project/${project.id}`, project)
  } else {
    // create project using POST
    response = await auth.postJson<ApiResponse>(`/projects/`, project)
  }

  if (response.statuscode !== 200) {
    throw new Error(response.message)
  }

  if (response.payload) {
    return response.payload[0] as IProject
  }

  throw new Error('No payload returned')
}

/**
 * registerUser is to register a new user to the backend
 * @param auth the auth object from useAuth
 * @param data the user data
 */
export async function registerUser(auth: AuthType, data: Asserts<typeof registerSchema>): Promise<ApiResponse> {
  const res = await auth.postJson<ApiResponse>('/register', data)
  return res
}

export interface LoginForm {
  username: string,
  password: string,
}

/**
 * login is to login a user to the backend
 * @param auth the auth object from useAuth
 * @param username the username or email
 * @param password the password
 * @returns the response from the backend
 */
export async function loginUser(auth: AuthType, { username, password }: LoginForm): Promise<ApiResponse> {
  const loginData: {
    username?: string,
    email?: string,
    password: string,
  } = {
    password
  }
  // check is username is an email
  if (username.indexOf('@') > 0) {
    loginData.email = username
  } else {
    loginData.username = username
  }

  const res = await auth.postJson<ApiResponse>('/auth', loginData)
  return res
}

export async function fetchTrackingEvents(auth: AuthType, options?: TrackingEventsPayload): Promise<ApiResponse> {
  const searchParams: Record<string, string> = {}
  if (options) {
    Object.entries(options).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        searchParams[key] = value.toString()
      }
    })
  }
  const params = new URLSearchParams(searchParams)
  const res = await auth.getJson<ApiResponse>(`/tracking_events?${params.toString()}`)
  return res
}

export async function fetchVersion(auth: AuthType, project: IProject): Promise<string> {
  let response: ApiResponse
  if (project.id && typeof project.id === 'number' && project.id > 0) {
    response = await auth.getJson<ApiResponse>(`/get-version-for-project-id/${project.id}`)
    if (response.statuscode !== 200) {
      throw new Error(response.message)
    }
    if (response.payload) {
      return response.payload[0]
    }
  }
  throw new Error('Project invalid')
}

export interface IOrganization {
  id: number
  name: string
}

export interface IUser {
  id: number
  email: string
  name?: string
  isApproved: boolean
  isAdmin: boolean
  organization?: IOrganization
}

export async function getUsers(auth: AuthType): Promise<IUser[]> {
  const res = await auth.getJson<ApiResponse<IUser>>('/users')
  return res.payload ?? []
}

export async function patchUser(auth: AuthType, userId: number, data: Asserts<typeof patchUserSchema>): Promise<IUser | undefined> {
  const res = await auth.patchJson<ApiResponse<IUser>>(`/users/${userId}`, data)
  return res.payload?.[0]
}
