function DownloadPage() {
  return (
    <div className="container-fluid">
      <h1>Download</h1>
      <a target='_blank' href='https://wixelpress.digitalwill.co.jp/wixelpress.zip' rel="noreferrer">Download the recent Plugin</a>
    </div>
  )
}


export default DownloadPage
