import { Field, ErrorMessage, FieldProps } from 'formik';
import classNames from 'classnames';

export const FormField = ({ name, fieldLabel, ...fieldProps }: {
  name: string;
  type?: string;
  disabled?: boolean;
  title?: string;
  fieldLabel: string;
  placeholder?: string;
}) => (
  <div className="form-group mt-3">
    <label className="form-label" htmlFor={name}>{fieldLabel}</label>
    <Field name={name}>
      {({ field, meta }: FieldProps<string>) => (
        <input
          className={classNames("form-control", { 'is-invalid': meta.touched && meta.error })}
          id={name}
          {...field}
          {...fieldProps}
        />
      )}
    </Field>
    <ErrorMessage className="invalid-feedback" component="div" name={name} />
  </div>
);

export const FormTextAreaField = ({ name, fieldLabel, placeholder }: {
  name: string,
  fieldLabel?: string,
  placeholder?: string,
}) => (
  <div className="form-group mt-3">
    <label className="form-label" htmlFor={name}>{fieldLabel || name}</label>
    <Field name={name}>
      {({ field, meta }: FieldProps<string>) => (
        <textarea
          className={classNames("form-control", { 'is-invalid': meta.touched && meta.error })}
          id={name}
          name={name}
          defaultValue={field.value}
          onChange={field.onChange}
          placeholder={placeholder}
        />
      )}
    </Field>
    <ErrorMessage className="invalid-feedback" component="div" name={name} />
  </div>
)
