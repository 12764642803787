import './App.css'
import './bootstrap.css'
import { useEffect } from 'react'
import { RouterProvider, Navigate, Outlet, useLocation, useParams, createBrowserRouter, useNavigate } from 'react-router-dom'
import { AuthProvider, useAuth } from './AuthProvider'
import Nav from './Nav'
import Projects from './Projects'
import Login from './Login'
import Events from './Events'
import Register from './Register'
import UserManagementPage from './UserManagementPage'
import DownloadPage from './DownloadPage'

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div className="App pt-5">
        <div className="py-3 h-100">
          <Nav />
          <Outlet />
        </div>
      </div>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "token/:token",
        element: <TokenPage />,
      },
      {
        path: "projects",
        element: <ProtectedRoute><Projects /></ProtectedRoute>,
      },
      {
        path: "events",
        element: <ProtectedRoute><Events /></ProtectedRoute>,
      },
      {
        path: "users",
        element: <ProtectedRoute><UserManagementPage /></ProtectedRoute>,
      },
      {
        path: "download",
        element: <DownloadPage />,
      }
    ],
  }
])

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  )
}

function ProtectedRoute({ children }: {
  children: JSX.Element
}): JSX.Element {
  const { token } = useAuth()
  const location = useLocation()
  if (!token) {
    return <Navigate to="/login" replace state={{ from: location }} />
  }
  return children
}

function IndexPage() {
  return (
    <main>
      <h1 className="visually-hidden">Heroes examples</h1>

      <div className="px-4 py-5 my-5 text-center">
        <img className="d-block mx-auto mb-4 mw-100" src="screen0.jpg" alt="" width="600px" height="100%" />
        <h1 className="display-5 fw-bold text-body-emphasis">The challenge</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">Cookies are in decline and marketers are losing as much as 70% of their data</p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <button type="button" className="btn btn-primary btn-lg px-4 gap-3">Primary button</button>
            <button type="button" className="btn btn-outline-secondary btn-lg px-4">Secondary</button>
          </div>
        </div>
      </div>

      <div className="b-example-divider"></div>

      <div className="px-4 pt-5 my-5 text-center border-bottom">
        <h1 className="display-4 fw-bold text-body-emphasis">Fire to different provider with one solution</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">Send signals directly from your website’s server without relying only on cookies</p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
            <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3">Primary button</button>
            <button type="button" className="btn btn-outline-secondary btn-lg px-4">Secondary</button>
          </div>
        </div>
        <div className="overflow-hidden" >
          <div className="container px-5">
            <img src="screen1.jpg" className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example image" width="700" height="500" loading="lazy" />
          </div>
        </div>
      </div>

      <div className="b-example-divider"></div>

      <div className="container col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <img src="screen2.jpg" className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
          </div>
          <div className="col-lg-6">
            <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">3rd party cookies are the past</h1>
            <p className="lead">Standardized events across channels for greater insights </p>
            <p className="lead">Sends both server and browser data for maximum accuracy </p>
            <p className="lead">Never worry about different rules on different platforms </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
              <button type="button" className="btn btn-primary btn-lg px-4 me-md-2">Primary</button>
              <button type="button" className="btn btn-outline-secondary btn-lg px-4">Default</button>
            </div>
          </div>
        </div>
      </div>

      <div className="b-example-divider"></div>

      <div className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row align-items-center g-lg-5 py-5">
          <div className="col-lg-7 text-center text-lg-start">
            <h1 className="display-4 fw-bold lh-1 text-body-emphasis mb-3">Get started</h1>
            <p className="col-lg-10 fs-4">Signup for wixel, create a project and install our solution.</p>
          </div>
          <div className="col-md-10 mx-auto col-lg-5">
            <form className="p-4 p-md-5 border rounded-3 bg-body-tertiary" data-bitwarden-watching="1">
              <div className="form-floating mb-3">
                <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                <label htmlFor="floatingInput">Email address</label>
              </div>
              <div className="form-floating mb-3">
                <input type="password" className="form-control" id="floatingPassword" placeholder="Password" />
                <label htmlFor="floatingPassword">Password</label>
              </div>
              <div className="checkbox mb-3">
                <label>
                  <input type="checkbox" value="remember-me" /> Remember me
                </label>
              </div>
              <button className="w-100 btn btn-lg btn-primary" type="submit">Sign up</button>
              <hr className="my-4" />
              <small className="text-body-secondary">By clicking Sign up, you agree to the terms of use.</small>
            </form>
          </div>
        </div>
      </div>

      <div className="b-example-divider"></div>
      <div className="b-example-divider"></div>

      <div className="bg-dark text-secondary px-4 py-5 text-center">
        <div className="py-5">
          <h1 className="display-5 fw-bold text-white">Wixel</h1>
          <div className="col-lg-6 mx-auto">
            <p className="fs-5 mb-4">One funnel for different providers with a one-click install and many customizations to specifically target events</p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <button type="button" className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold">Custom button</button>
              <button type="button" className="btn btn-outline-light btn-lg px-4">Secondary</button>
            </div>
          </div>
        </div>
      </div>

      <div className="b-example-divider mb-0"></div>
    </main>
  )
}

function TokenPage() {
  const { token } = useParams()
  const auth = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/events', { replace: true })
    }
  }, [auth.isAuthenticated, navigate])
  auth.setToken(token as string)
  return <div>Please wait...</div>
}

export default App
