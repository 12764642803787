import { useState, useEffect } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { loginUser } from './helpers'
import { useAuth } from './AuthProvider'
import { loginSchema } from './schema'
import { FormField } from './Components'


export default function Login() {
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (success && auth.isAuthenticated) {
      navigate(location.state?.from?.pathname || "/events", { replace: true })
    }
  }, [success, auth.isAuthenticated, location.state?.from?.pathname, navigate])

  return (
    <div className="d-flex h-100 w-100 flex-column align-items-center justify-content-center">
      <div className="card shadow p-3 w-100" style={{ maxWidth: 300 }}>
        <h1 className="h3">Wixel login</h1>
        <Formik
          initialValues={{ username: '', password: '' }}
          validationSchema={loginSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const resp = await loginUser(auth, values)
              if (resp.statuscode !== 200) {
                throw new Error(resp.message)
              }
              const token = resp.payload[0]
              setError("")
              auth.setToken(token)
              setSuccess(true)
              // navigate(location.state?.from?.pathname || "/events", { replace: true })
            } catch (err) {
              console.error('Login Error', err)
              if (err instanceof Error) {
                setError(err.message)
              } else {
                setError('Something went wrong')
              }
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormField name="username" placeholder="your name" fieldLabel="Email / username" />
              <FormField type="password" name="password" placeholder="enter your password" fieldLabel="Password" />
              {error && (
                <div className="error">
                  {error}
                </div>
              )}
              <div className="mt-3 d-flex flex-row align-items-baseline">
                <Link className="btn btn-success flex-grow-1" to='/register'>Register</Link>
                <div className="mx-1"></div>
                <button className="btn btn-primary flex-grow-1" type="submit" disabled={isSubmitting}>Login</button>
              </div>
            </Form>
          )}
        </Formik>

      </div>
    </div>
  )
}
