import { Link, NavLink } from "react-router-dom"
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { useAuth } from "./AuthProvider"


export default function Nav() {
  const { isAuthenticated, payload, resetToken } = useAuth()
	const location = useLocation()
  const isInIframe = window.location !== window.parent.location

	return (
		<nav className="navbar w-100 fixed-top navbar-expand bg-light">
			<div className="container-fluid">
				<Link className="navbar-brand" to="/">Wixel 🤘</Link>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            <li className={classNames(["nav-item"], {"border-2 border-bottom border-primary": location.pathname.startsWith('/events')})}>
              <Link className={classNames(['nav-link'], {active: location.pathname.startsWith('/events')})} to="/events">Events</Link>
            </li>
            <li className={classNames(["nav-item"], {"border-2 border-bottom border-primary": location.pathname.startsWith('/projects')})}>
              <Link className={classNames(['nav-link'], {active: location.pathname.startsWith('/projects')})} to="/projects">Projects</Link>
            </li>
            { isAuthenticated && (payload?.isAdmin || (payload as any).isSuperuser) && (
              <>
                <li className={classNames(["nav-item"], { "border-2 border-bottom border-primary": location.pathname.startsWith('/users') })}>
                  <NavLink className={({ isActive }) => classNames(['nav-link'], { active: isActive })} to="/users">Users</NavLink>
                </li>
              </>
            )}
            <li className={classNames(["nav-item"], { "border-2 border-bottom border-primary": location.pathname.startsWith('/download') })}>
              <NavLink className={({ isActive }) => classNames(['nav-link'], { active: isActive })} to="/download">Download</NavLink>
            </li>
          </ul>
          <ul className="navbar-nav">
            { isAuthenticated ? (
              <>
              <li className="nav-item">
                <div className="nav-link">{`👤 ${payload!.name ?? payload!.email}`}</div>
              </li>
                {!isInIframe && (
                  <li className="nav-item">
                    <button onClick={resetToken} className="btn border-0 nav-link text-danger">Logout</button>
                  </li>
                )}
              </>
            ): (
              <>
              <li className={classNames(["nav-item"], {"border-2 border-bottom border-primary": location.pathname.startsWith('/login')})}>
                <Link className={classNames(['nav-link'], {active: location.pathname.startsWith('/login')})} to="/login">Login</Link>
              </li>
              <li className={classNames(["nav-item"], {"border-2 border-bottom border-primary": location.pathname.startsWith('/register')})}>
                <Link className={classNames(['nav-link'], {active: location.pathname.startsWith('/register')})} to="/register">Sign up</Link>
              </li>
              </>
            )}
          </ul>
				</div>
			</div >
		</nav >
	)
}
