import type { ReactNode } from 'react'
import classNames from 'classnames'

const ModalDialog = ({ children, isOpen, onClose, title, footer }: {
  children: ReactNode
  isOpen?: boolean
  onClose: () => void
  title: string
  footer?: ReactNode
}) => (
  <div className={classNames(['modal'], { 'd-block': isOpen })} tabIndex={-1}>
    <div className="modal-dialog modal-fullscreen-sm-down modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <button type="button" onClick={onClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          {children}
        </div>
        {footer && (
          <div className="modal-footer">
            {footer}
          </div>
        )}
      </div>
    </div>
    {isOpen && (
      <div
        className={classNames({"modal-backdrop fade show": isOpen})}
        tabIndex={-1}
        onClick={onClose}
      >
      </div>
    )}
  </div>
)

export default ModalDialog
