import { useState, createContext, useContext, useMemo, ReactNode } from 'react'
import { IAuth } from './schema'

const TokenContext = createContext<ReturnType<typeof useState<string | null | undefined>>>([
  null,
  () => null,
])

export function AuthProvider({ children }: {
  children: ReactNode
}) {
  const tokenState = useState<string | null | undefined>(() => window.localStorage.getItem("dw_wixel_jwt_token"))
  return (
    <TokenContext.Provider value={tokenState}>
      {children}
    </TokenContext.Provider>
  )
}

export function useAuth() {
  const [token, _setToken] = useContext(TokenContext)
  const auth = useMemo(() => {
    const isAuthenticated = !!token

    const setToken = (newToken: string) => {
      window.localStorage.setItem("dw_wixel_jwt_token", newToken)
      _setToken(newToken)
    }

    const resetToken = () => {
      window.localStorage.removeItem("dw_wixel_jwt_token")
      _setToken(null)
    }

    const fetchJson = async <T=any>(path: string | URL, init?: RequestInit): Promise<T> => {
      const options = { ...init }
      options.headers = options.headers || {}
      if (!!token) {
        options.headers = {
          ...options.headers,
          "Authorization": `Bearer ${token}`
        }
      }
      const response = await fetch(`${process.env.REACT_APP_NOT_BACKEND_HOST}${path}`, options)
      const contentType = response.headers.get('Content-Type')
      if (response.status === 401 && !!token) {
        resetToken()
      }
      if (response.status >= 400) {
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const result = await response.json()
          if (result.message) {
            throw new Error(result.message)
          } else {
            throw new Error(JSON.stringify(result))
          }
        }
        throw new Error(await response.text())
      }
      const data = await response.json() as T
      return data
    }

    const getJson = async <T=any>(path: string | URL): Promise<T> => {
      return fetchJson(path)
    }

    const postJson = async <T=any>(path: string | URL, payload: any): Promise<T> => {
      return fetchJson(path, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      })
    }

    const putJson = async <T=any>(path: string | URL, payload: any): Promise<T> => {
      return fetchJson(path, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      })
    }

    const patchJson = async <T=any>(path: string | URL, payload: any): Promise<T> => {
      return fetchJson(path, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      })
    }

    const payload: IAuth | undefined = isAuthenticated ? JSON.parse(window.atob(token.split('.')[1])): undefined

    return {
      token,
      payload,
      setToken,
      resetToken,
      isAuthenticated,
      getJson,
      postJson,
      putJson,
      patchJson,
    }
  }, [token, _setToken])

  return auth
}

export type AuthType = ReturnType<typeof useAuth>
