import { useState, useEffect, ChangeEvent } from 'react'
import { useAuth } from './AuthProvider'
import { getUsers, patchUser, IUser } from './helpers'

function UserManagementPage() {
  return (
    <div className="container-fluid">
      <h1>User Management</h1>
      <UserList />
    </div>
  )
}

function UserList() {
  const auth = useAuth()
  const [isLoading, setLoading] = useState(false)
  const [users, setUsers] = useState<IUser[]>([])

  useEffect(() => {
    (async () => {
      setLoading(true)
      const response = await getUsers(auth)
      setUsers(response)
      setLoading(false)
    })()
  }, [auth])

  const handleUserChange = (userId: number, fields: 'isApproved' | 'isAdmin') => async (ev: ChangeEvent<HTMLInputElement>) =>{
    try {
      const response = await patchUser(auth, userId, { [fields]: ev.target.checked })
      if (response) {
        setUsers(users.map(user => user.id === userId ? response : user))
      }
    } catch (err) {
      console.error(err)
      if (err instanceof Error) {
        window.alert(err.message)
      }
    }
  }

  if (!auth.payload || (!auth.payload.isAdmin && !auth.payload.isSuperuser)) {
    return <div>You are not authorized</div>
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <table className="table table-sm">
      <thead>
        <tr>
          <th>Email</th>
          <th>Organization</th>
          <th>Approved</th>
          <th>Admin</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr key={user.id}>
            <td>{user.email}</td>
            <td>{user.organization?.name ?? '-'}</td>
            <td>
              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" checked={user.isApproved} onChange={handleUserChange(user.id, 'isApproved')} disabled={user.email === auth.payload?.email}/>
              </div>
            </td>
            <td>
              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" checked={user.isAdmin} onChange={handleUserChange(user.id, 'isAdmin')} disabled={user.email === auth.payload?.email}/>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default UserManagementPage;
