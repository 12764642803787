import * as yup from 'yup'

export interface IAuth {
  email: string
  name?: string
  isAdmin: boolean
  isSuperuser: boolean
  role: string
}

export interface ApiResponse<T=any> {
  statuscode: number
  message: string
  payload: T[]
}

// TODO: move these schemas to wixel_interfaces, but the tests and the validation should also works
export const registerSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
  confirmPassword: yup.string().label('confirm password').required().oneOf([yup.ref('password')], 'Passwords must match'),
  name: yup.string().label('fullname').notRequired(),
  organizationName: yup.string().label('organization').notRequired(),
})

export const loginSchema = yup.object({
  username: yup.string().required(),
  password: yup.string().required(),
})

export const gaSchema = yup.object({
  id: yup.number().integer().notRequired(),
  measurementId: yup.string().label('Measurement ID').matches(/^(G|UA|YT|MO)-[a-zA-Z0-9-]+$/, 'Incorrect Measurement ID format'),
  projectId: yup.string().notRequired(),
  apiKey: yup.string().notRequired(),
  authDomain: yup.string().notRequired(),
  storageBucket: yup.string().notRequired(),
  messagingSenderId: yup.string().notRequired(),
  appId: yup.string().notRequired(),
})

export type IFirebaseConfig = yup.Asserts<typeof gaSchema>

export const firebaseConfigSchema = gaSchema.shape({
  measurementId: yup.string().required(),
  apiKey: yup.string().required(),
  authDomain: yup.string().required(),
  projectId: yup.string().required(),
  storageBucket: yup.string().required(),
  messagingSenderId: yup.string().required(),
  appId: yup.string().required(),
})

export const fbSchema = yup.object({
  id: yup.number().integer().notRequired(),
  pixelId: yup.string().label('Pixel ID').matches(/^\d+$/, 'Pixel ID should only contain numbers').notRequired(),
  fbAccessToken: yup.string().label('FB Access Token').when('pixelId', {
    is: (pixelId?: string) => (!!pixelId && pixelId.trim() !== ''),
    then: (schema: yup.Schema) => schema.required(),
    otherwise: (schema: yup.Schema) => schema.notRequired(),
  }),
  testEventCode: yup.string().notRequired(),
})

export type IFacebookConfig = yup.Asserts<typeof fbSchema>

export const projectSchema = yup.object({
  id: yup.number().integer().notRequired(),
  name: yup.string().label('Project Name').required(),
  selectors: yup.string().notRequired().test(
    'is-json',
    'Selectors must be a valid JSON string',
    (value: string | null | undefined) => {
      if (!value) {
        return true
      }
      try {
        JSON.parse(value)
        return true
      } catch (e) {
        return false
      }
    },
  ),
  organizationId: yup.number().integer().notRequired(),
  stripeCustomerId: yup.string().notRequired(),
  subscriptionEnd: yup.date().notRequired(),
  fbConfig: fbSchema.notRequired(),
  gaConfig: gaSchema.notRequired(),
  createdAt: yup.date().notRequired(),
  updatedAt: yup.date().notRequired(),
})

export type IProject = yup.Asserts<typeof projectSchema>

export const projectsListSchema = yup.array().of(projectSchema).ensure()

export const patchUserSchema = yup.object({
  isApproved: yup.bool().optional(),
  isAdmin: yup.bool().optional(),
})
