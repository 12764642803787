import { useEffect, useState, useRef, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ITrackingEvent } from "wixel_interfaces"
import classNames from 'classnames'
import { wFetch } from "./helpers"
import { useAuth } from "./AuthProvider"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
  maintainAspectRatio: false
}

let firstEventsCount: string[] = []
function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}
export default function Events(props: any) {
  const navigate = useNavigate()
  const auth = useAuth()
  const [events, setevents] = useState<any>([])
  const [displayedEvents, setDisplayedEvents] = useState<any>([])
  const [filterByTerm, setfilterByTerm] = useState<string[]>([])
  const [stats, setstats] = useState<any>([])
  const [overview, setOverview] = useState<any>({ stats: [], hits: [] })
  const [total, setTotal] = useState<any>(0)
  const [overviewopen, setoverviewopen] = useState(false)
  const [eventGroupOpen, seteventGroupOpen] = useState(true)
  const [modalOpen, setmodalOpen] = useState<ITrackingEvent | undefined>(undefined)
  const eventsRef = useRef("")
  const overviewRef: any = useRef({ current: null })
  const [loading, setloading] = useState(false)
  const loadingRef = useRef(false)
  const loadedRef = useRef(false)
  let query = useQuery()
  const queryRef = useRef(query)

  useEffect(() => {
    eventsRef.current = events
  }, [events])
  useEffect(() => {
    overviewRef.current = overview
  }, [overview])

  useEffect(() => {
    overviewRef.current = overview
  }, [overview])

  useEffect(() => {
    queryRef.current = query
  }, [query])

  useEffect(() => {
    loadingRef.current = loading
  }, [loading])

  const groupByTenMinutes = (array: ITrackingEvent[]) => {
    let finalObj: any = []
    array.forEach((games) => {
      if (games.createdAt) {
        const date = games.createdAt.toString().slice(0, 15)
        if (finalObj[date]) {
          finalObj[date].push(games)
        } else {
          finalObj[date] = [games]
        }
      }
    })
    return finalObj
  }

  const groupByHits = (array: ITrackingEvent[]) => {
    const hits: any = array.reduce((p, c) => {
      var name = c.referer
      if (!p.hasOwnProperty(name)) {
        // @ts-ignore
        p[name] = 0
      }
      // @ts-ignore
      p[name]++
      return p
    }, {})
    var sortArray = []
    // convert the list to array of key and value pair
    for (let i in hits) {
      sortArray.push({ url: i, value: hits[i] })
    }
    sortArray.sort(function (a, b) {
      return b.value - a.value
    })
    return sortArray
  }

  const generateChartData = (events: ITrackingEvent[]) => {
    const eventsPerDay = events.reduce<string[]>((acc, doc) => {
      var date = doc.createdAt
      var day: string = date!.toString().slice(0, 10)
      if (!(day in acc)) {
        // @ts-ignore
        acc[day] = {
          hits: 1,
        }
      } else {
        // @ts-ignore
        acc[day]['hits']++
      }
      return acc
    }, [])
    let lastThirtyDays = []
    for (let index = 30; index >= 0; index--) {
      var today = new Date()
      var priorDate = new Date(new Date().setDate(today.getDate() - index))
      const date = priorDate.toISOString().slice(0, 10)
      // @ts-ignore
      lastThirtyDays.push({ date, hits: eventsPerDay[date]?.hits || 0 })
    }
    const data = {
      labels: lastThirtyDays.map(l => l.date),
      datasets: [
        {
          label: 'Events',
          data: lastThirtyDays.map(l => l.hits),
          borderColor: '#0d6efd',
          backgroundColor: '#0d6efd',
        },
      ],
    }
    setstats(data)
  }

  const fetchEvents = (firstFetch = false, project_id: number | undefined = queryRef.current.get("project_id") ? Number(queryRef.current.get("project_id")) : undefined) => {
    setloading(true)
    const myUrlWithParams = new URL(`${window.location.origin}/tracking_event`)
    const searchParams = new URLSearchParams(window.location.search)
    if (project_id) {
      myUrlWithParams.searchParams.append("project_id", project_id.toString())
    }
    const limit = searchParams.get("limit")
    if (firstFetch) {
      myUrlWithParams.searchParams.append("limit", "100000")
    } else if (limit) {
      myUrlWithParams.searchParams.append("limit", limit.toString())
    } else {
      myUrlWithParams.searchParams.append("limit", "1000")
    }
    myUrlWithParams.searchParams.append("since_days", "90")
    wFetch(`/tracking_events${myUrlWithParams.search}`, {}, ((res: any) => {
      setloading(false)
      if (res.statuscode === 200) {
        if (firstFetch) {
          firstEventsCount = res.payload.map((trackingEvent: ITrackingEvent) => trackingEvent.event_id)
        }
        if ("event_id" in res.payload[0]) {
          setDisplayedEvents(res.payload.slice(0, 1000))
          let newEvents = res.payload
          newEvents = newEvents
            .filter((event: any) => !firstEventsCount.includes(event.event_id))
          if (firstFetch) {
            const newOverview = { stats: groupByEventType(res.payload), hits: groupByHits(res.payload).slice(0, 10) }
            setOverview(newOverview)
          generateChartData(res.payload)
            setTotal(res.payload.length)
            setevents(res.payload.map((e: any) => ({ event_id: e.event_id, createdAt: e.createdAt, event_type: e.event_type })))
          } else if (newEvents.length > 0) {
            const newMappedEvents = [...eventsRef.current, ...newEvents.map((e: any) => ({ event_id: e.event_id, createdAt: e.createdAt, event_type: e.event_type }))]
            setevents(newMappedEvents)
            setTotal(eventsRef.current.length)
          }
        }
      }
      // setTimeout(() => {
      //   setloading(false)
      // }, 1000)
    })
    )
  }

  useEffect(() => {
    if (!loadedRef.current) {
      loadedRef.current = true
      fetchEvents(true)
    }
  }, [auth])

  useEffect(() => {
    if (filterByTerm.length > 0) {
      const filtered = events.filter((event: any) => filterByTerm.includes(event.event_type))
      generateChartData(filtered)
    } else {
      generateChartData(events)
    }
  }, [filterByTerm, events])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!loadingRef.current) {
        fetchEvents()
      }
    }, 5000)
    return () => clearInterval(interval)
  }, [])


  const groupByEventType = (array: ITrackingEvent[]) => {
    let finalObj: any = []
    array.forEach((event) => {
      const event_name = event.event_type
      if (!(event_name in finalObj)) {
        finalObj[event_name] = 0
      } else {
        finalObj[event_name]++
      }
    })
    return finalObj
  }

  return (
    <div className="container-fluid">
      <h2>Total events: <code>{total}</code></h2>
      <div className="row">
        <div className="col-md-6">
          <div className="chart">
            {Object.keys(stats).length > 0 && (
              <Line options={options} data={stats} />
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="accordion mt-3" id="accordion_overview1">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button onClick={e => {
                  seteventGroupOpen(!eventGroupOpen)
                  setoverviewopen(!overviewopen)
                }} className={`accordion-button ${eventGroupOpen ? `collapse show` : `collapsed`}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseTwo">
                  Events by type
                </button>
              </h2>
              <div id="collapseOne" data-bs-parent="#accordion_overview1" className={`accordion-collapse ${eventGroupOpen ? `collapse show` : `collapse`}`} aria-labelledby="headingOne">
                <div className="accordion-body table-responsive">
                  <table className="table">
                    <tbody>
                      {'stats' in overviewRef?.current && Object.keys(overviewRef.current.stats).map((hit: any, index: any) => (
                        <tr key={`hit_${index}`}>
                          <td>
                            <button
                              onClick={e => {
                                if (filterByTerm.includes(hit)) {
                                  const newTerms = filterByTerm.filter((term: any) => term !== hit)
                                  setfilterByTerm(newTerms)
                                } else {
                                  let filterByTermClone = [...filterByTerm]
                                  filterByTermClone.push(hit)
                                  setfilterByTerm(filterByTermClone)
                                }
                              }}
                              className={`btn text-nowrap ps-1 pe-3 py-0 position-relative me-4 ${filterByTerm.includes(hit) ? `btn-primary` : `btn-outline-primary`}`}>{hit} - {overviewRef.current.stats[hit] + 1}
                              <span className="position-absolute top-0 start-100 translate-middle p-2 bg-success border border-light rounded-circle">
                                <span className="visually-hidden">
                                  New alerts
                                </span>
                              </span>
                            </button>
                          </td>
                          <td className="text-start text-nowrap">
                            Last event fired <code>{Math.floor(((new Date()).getTime() - (new Date(events.find((event: any) => event.event_type === hit)?.createdAt)).getTime()) / (1000 * 3600))}</code> hours ago
                          </td>
                          <td className="text-end text-nowrap d-none d-md-block">
                            on <code>{events.find((event: any) => event.event_type === hit)?.createdAt?.toString().slice(0, 19).replace('T', ' ')}</code>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion" id="accordion_overview2">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button onClick={e => {
                  setoverviewopen(!overviewopen)
                  seteventGroupOpen(!eventGroupOpen)
                }} className={`accordion-button ${overviewopen ? `collapse show` : `collapsed`}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Traffic-peaks
                </button>
              </h2>
              <div id="collapseOne" className={`accordion-collapse ${overviewopen ? `collapse show` : `collapse`}`} aria-labelledby="headingOne" data-bs-parent="#accordion_overview2">
                <div className="accordion-body">
                  <ul className="list-group">
                    {'hits' in overviewRef?.current ? overviewRef.current.hits.map((hit: any, index: number) => (
                      <li className="list-group-item" key={`hit_${index}`}>{hit.value} - {hit.url}</li>
                    )) : null}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(['modal'], { 'd-block': modalOpen })} tabIndex={-1}>
            {modalOpen !== undefined && (
              <>
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">{modalOpen.event_type} - {modalOpen.event_id}</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={e => setmodalOpen(undefined)}></button>
                    </div>
                    <div className="modal-body">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <th scope="col">created</th>
                            <td className="text-nowrap">{modalOpen.createdAt ? new Date(modalOpen.createdAt).toLocaleString('sv', { timeZone: 'Asia/Tokyo' }) : ``}</td>
                          </tr>
                          <tr>
                            <th scope="col">event_id</th>
                            <td>{modalOpen.event_id}</td>
                          </tr>
                          {auth?.payload?.isAdmin ? (
                            <tr>
                              <th scope="col">project</th>
                              <td>{modalOpen.project_id}</td>
                            </tr>
                          ) : null}
                          <tr>
                            <th scope="col">event_type</th>
                            <td>{modalOpen.event_type}</td>
                          </tr>
                          <tr>
                            <th scope="col">referer</th>
                            <td>{modalOpen.referer}</td>
                          </tr>
                          {auth?.payload?.isAdmin ? (
                            <>
                              <tr>
                                <th scope="col">project</th>
                                <td>{modalOpen.project_id}</td>
                              </tr>
                              <tr>
                                <th scope="col">api_response</th>
                                <td className="text-break">{modalOpen.api_response}</td>
                              </tr>
                              <tr>
                                <th scope="col">user_data</th>
                                <td className="text-break">{modalOpen.user_data?.replace(/","/g, '",\n\r"')}</td>
                              </tr>
                              <tr>
                                <th scope="col">custom_data</th>
                                <td className="text-break">{modalOpen.custom_data?.replace(/","/g, '",\n\r"')}</td>
                              </tr>
                            </>
                          ) : null}
                        </tbody>
                      </table>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setmodalOpen(undefined)}>Close</button>
                    </div>
                  </div>
                </div>
                <div className={` ${modalOpen !== undefined ? `modal-backdrop show` : ``}`} tabIndex={-1} onClick={e => {
                  setmodalOpen(undefined)
                }}></div>
              </>
            )}
          </div>
        </div>
      </div>

      {displayedEvents.length > 0 && (
        <>
          <h2 className="mt-3">Last 1000 events</h2>
          <div className="row table-responsive">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">created</th>
                  <th scope="col">event_id</th>
                  {auth.payload?.isAdmin ? (
                    <th scope="col">
                      <button
                        onClick={e => {
                          navigate(`/events`)
                          fetchEvents(false, undefined)
                        }}
                        className="btn btn-link p-0">
                        project
                      </button>
                    </th>
                  ) : null}
                  <th scope="col">event_type</th>
                  <th scope="col">referer</th>
                  {auth?.payload?.isSuperuser ? (
                    <>
                      <th scope="col">api_response</th>
                      <th scope="col">user_data</th>
                      <th scope="col">custom_data</th>
                    </>
                  ) : null}
                  {/* TODO as soon we implement a queue system, this is the indicator if the event needs to still be fired */}
                  {/* <th scope="col">triggered</th> */}
                  {/* <th scope="col">api_response</th>
                <th scope="col">user_data</th>
                <th scope="col">custom_data</th> */}
                </tr>
              </thead>
              {/* TODO grouped events by freshness */}
              {/* {Object.keys(groupedEvents).map((tenmin: any, index2: number) => ( */}
              <tbody
              // key={index2}
              >
                {displayedEvents.map((event: ITrackingEvent, index: number) => (
                  <tr key={events.length - index} >
                    <th onClick={e => setmodalOpen(event)} scope="row" className={`${index === displayedEvents.length - 1 ? `pb-5` : ``}`}><button className="btn btn-primary px-1 py-0 position-relative">{total - displayedEvents.findIndex((e: any) => event.event_id === e.event_id)}
                      {!firstEventsCount.includes(event.event_id) && (

                        <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                          <span className="visually-hidden">New alerts</span>
                        </span>
                      )}
                    </button></th>
                    <td className="text-nowrap">{event.createdAt ? new Date(event.createdAt).toLocaleString('sv', { timeZone: 'Asia/Tokyo' }) : ``}</td>
                    <td>{event.event_id}</td>
                    {auth?.payload?.isAdmin ? (
                      <td
                        onClick={e => {
                          navigate(`/events?project_id=${event.project_id}`)
                          fetchEvents(false, event.project_id)
                        }}
                      >
                        <button className="btn btn-link p-0">
                          {event.project_id}
                        </button>
                      </td>
                    ) : null}
                    <td>{event.event_type}</td>
                    <td className="text-truncate">{event.referer}</td>
                    {/* <td className="text-truncate">{event.triggered}</td> */}
                    <td className="text-truncate">{event.api_response}</td>
                    <td className="text-truncate">{event.user_data?.replace(/","/g, '",\n\r"')}</td>
                    <td className="text-truncate">{event.custom_data?.replace(/","/g, '",\n\r"')}</td>
                  </tr>
                ))}
              </tbody>
              {/* ))} */}
            </table>
          </div>
        </>
      )}
    </div>
  )
}
