import { useEffect, useState } from "react"
import { IProject } from "./schema"
import { fetchProjects, fetchVersion } from "./helpers"
import ProjectForm from "./ProjectForm"
import { useAuth } from "./AuthProvider"

export default function Projects() {
  const auth = useAuth()
  const [projects, setProjects] = useState<IProject[]>([])
  const [versions, setversions] = useState<{ id: number, version: string }[] | []>([])

  const initProject = (): IProject => ({
    id: -1,
    name: '',
    selectors: '',
    subscriptionEnd: null,
    fbConfig: {
      pixelId: '',
      fbAccessToken: '',
      testEventCode: '',
    },
    gaConfig: {
      measurementId: '',
      apiKey: '',
      appId: '',
      authDomain: '',
      messagingSenderId: '',
      storageBucket: '',
    }
  })

  const openProjectEditor = (project: IProject) => {
    setEditProject({
      id: project.id,
      name: project.name ?? '',
      selectors: project.selectors ?? '',
      subscriptionEnd: project.subscriptionEnd ?? null,
      fbConfig: {
        pixelId: project.fbConfig?.pixelId ?? '',
        fbAccessToken: project.fbConfig?.fbAccessToken ?? '',
        testEventCode: project.fbConfig?.testEventCode ?? '',
      },
      gaConfig: {
        measurementId: project.gaConfig?.measurementId ?? '',
        apiKey: project.gaConfig?.apiKey ?? '',
        appId: project.gaConfig?.appId ?? '',
        authDomain: project.gaConfig?.authDomain ?? '',
        messagingSenderId: project.gaConfig?.messagingSenderId ?? '',
        storageBucket: project.gaConfig?.storageBucket ?? '',
      }
    })
    setEditorOpen(true)
  }
  const [editProject, setEditProject] = useState<IProject>(initProject)
  const [isEditorOpen, setEditorOpen] = useState(false)

  const loadProjects = async () => {
    const response = await fetchProjects(auth)
    setProjects(response || [])
  }

  useEffect(() => {
    fetchProjects(auth).then((res) => setProjects(res || []))
  }, [auth])

  return (
    <div className="container-fluid">
      <div className="d-flex flex-row w-100 justify-content-baseline">
        <h1 className="h3">
          Projects
        </h1>
        <button
          className="btn btn-primary ms-3"
          onClick={() => {
            openProjectEditor(initProject())
          }}
        >
          New
        </button>
      </div>
      <ProjectForm
        isOpen={isEditorOpen}
        project={editProject}
        onSave={() => {
          loadProjects()
        }}
        onClose={() => setEditorOpen(false)}
      />
      {projects && projects.length > 0 && (
        <div className="row table-responsive">
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col">Action</th>
                {auth?.payload?.isAdmin ? (
                  <th scope="col">Version</th>
                ) : null}
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                {/* <th scope="col">Selectors</th> */}
                {/* <th scope="col">FB pixel id</th>
                <th scope="col">FB test event code</th>
                <th scope="col">GA4 firebase config</th> */}
                <th scope="col">createdAt</th>
                <th scope="col">updatedAt</th>
              </tr>
            </thead>
            <tbody >
              {projects.map((project: IProject, index: number) => (
                <tr key={project.id}>
                  <td>
                    <button className="btn btn-primary" onClick={(e) => {
                      // apply formatting for JSON
                      if (project.selectors) {
                        var sel = JSON.parse(project.selectors)
                        var prettySelectorsa = JSON.stringify(sel, undefined, 4)
                        project.selectors = prettySelectorsa
                      }
                      openProjectEditor(project)
                    }}>edit</button>
                  </td>
                  {/* {auth?.payload?.isAdmin ? (
                    <td>
                      {!versions.find(v => v.id === project.id)?.version ? (
                        <button className="btn btn-outline-primary" onClick={async (e) => {
                          try {
                            const res = await fetchVersion(auth, project)
                            console.log('res', res)
                            const versionClone = [...versions]
                            const index = versionClone.findIndex(i => i.id === project.id)
                            if (project.id) {
                              const payload = { id: project.id, version: res }
                              if (index > -1) {
                                versionClone[index] = payload
                              } else {
                                versionClone.push(payload)
                              }
                              setversions(versionClone)
                            }
                          } catch (error) {
                            console.log('error', error)
                          }
                        }}>{"Fetch installed plugin version"}</button>
                      ) : versions.find(v => v.id === project.id)?.version}
                    </td>
                  ) : null} */}
                  <th scope="row">{project.id}</th>
                  <td>{project.name}</td>
                  {/* <td>{project.selectors.replace(/:/g,':\n\r')}</td> */}
                  {/* <td>{project.fbConfig.replace(/:/g,':\n\r')}</td> */}
                  {project.createdAt && <td>{new Date(project.createdAt).toLocaleString('sv', { timeZoneName: 'short' })}</td>}
                  {project.updatedAt && <td>{new Date(project.updatedAt).toLocaleString('sv', { timeZoneName: 'short' })}</td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
